<template>
	<div>
		<a-spin :spinning="loading">
			<div class="login-main">
				<div class="main">
					<div class="login-main-logo flex center">
						<img  src="../assets/image/logo_vipcard@2x.png"/>
					</div>
					<div class="flex center">
						<div class="login-main-form">
							<div class="text-center cl-black ft24 ftwblod">欢迎登录</div>
							<div class="mt40">
								<a-input placeholder="员工手机号" v-model="form.mobile"/>
								<a-input type="password" placeholder="登陆密码" v-model="form.password"/>
							</div>
							<div class="login-button" @click="loginAct()">立即登录</div>
						</div>
					</div>
				</div>
				
				<div class="login-footer">
					<img src="../assets/image/cashier_bg@2x.png"/>
				</div>
				
			</div>
		</a-spin>
		
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				agree:false,
				loading:false,
				form:{
					mobile:'',
					password:'',
				}
			}
		},
		mounted() {
			//绑定事件
			window.addEventListener('keydown', this.keyDown);
		},
		created() {
			
		},
		methods:{
			loginAct(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('login',{
					mobile:this.form.mobile,
					password:this.form.password,
				}).then(res=>{
					this.loading=false;
					localStorage.setItem('cashier-beautiful-token',res.auth_token);
					this.$message.success('登录成功', 1, () => {
						this.$router.push('/index');
					});
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			keyDown(e) {
				//如果是回车则执行登录方法
				if (e.keyCode == 13) {
					this.loginAct();
				}
			},
		},
		destroyed() {
			window.removeEventListener('keydown', this.keyDown, false);
		}
	}
</script>

<style >
	.login-main {
		width: 100vw;
		height: 100vh;
		background: linear-gradient(315deg, #8C4CFD 0%, #5333FF 100%);
		z-index: 999;
	}
	.login-main .main{
		width: 100%;
		height: 100%;
	}
	.login-main .main .login-main-logo img{
		width: 230px;
		height: 88px;
	}
	
	.login-main-form .ant-input{
		height: 60px;
		background: #FFFFFF;
		border-radius: 30px;
		border: 1px solid #E6E6E6;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8A8D99;
		text-align: center;
		margin-bottom: 24px;
	}
	
	.login-button{
		height: 60px;
		background: #5333FF;
		border-radius: 30px;
		font-size: 24px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
		margin-top: 8px;
		cursor: pointer;
	}
	
	.login-footer{
		height: 170px;
		position: fixed;
		bottom: 0px;
	}
	
	.login-footer img{
		height: 170px;
	}
	
	
	@media screen and (max-width: 1024px) {
	   .login-main .main .login-main-logo{
		  padding-top: 50px;
	   }
	   
	   .login-main-form{
			width: 420px;
			height: 400px;
			background: #FFFFFF;
			box-shadow: 0px 4px 32px -8px rgba(0, 0, 0, 0.02);
			border-radius: 24px;
			margin-top: 40px;
			padding: 48px 40px 48px 40px;
	   }
	}
	
	@media screen and (min-width:1025px) and (max-width: 1920px) {
		.login-main .main .login-main-logo{
			padding-top: 70px;
		}
		
		.login-main-form{
			width: 420px;
			height: 400px;
			background: #FFFFFF;
			box-shadow: 0px 4px 32px -8px rgba(0, 0, 0, 0.02);
			border-radius: 24px;
			margin-top: 40px;
			padding: 48px 40px 48px 40px;
		}
	}
</style>

